//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "EventPublicJoin",
    props: ['id'],
    methods: {
      join() {
        this.$http({
          url: '/events/' + this.id + '/join',
          method: 'POST'
        }).then((response) => {
          if (response.data['status'] == 'event_joined') {
            this.$q.notify({
              message: 'You have successfully joined this event.',
              color: 'positive',
              icon:'check_circle'
            });
            window.location.reload();
            return;
          }
          if (response.data['status'] == 'already_member') {
            this.$q.notify({
              message: 'You are already a part of this event.',
              color: 'primary',
              icon: 'feedback'
            });
            return;
          }
          if (response.data['status'] == 'event_private') {
            this.$q.dialog({
              message: 'This event has switched to private and only the event captain can invite members into the group.',
              title: 'This event is private'
            });
            return;
          }
        })
      }
    }
}
